import '../styles/web-view.scss';
import '../styles/title-header.scss';
import '../styles/checkbox.scss';
import '../styles/order-view-footer.scss';
import '../styles/order-view-confirmation.scss';
import React, { useState, useEffect } from 'react';
import FeatureList from '../components/feature-list';
import { premiumFeatures } from '../data/content';
import PriceInfo from '../components/price-info';
import { BACKEND_API_ROOT, BACKEND_API_VERSION } from '../config';
import { UserInfoForm } from '../components/user-info-form';
import { AdyenCreditCardForm } from '../components/adyen-credit-card-form';
import ScrollToTop from '../components/scroll-to-top';
import * as jwt from 'jsonwebtoken';
import { Link } from 'react-router-dom';
import { AppContext } from '../context/app-context';
import Processing from '../components/processing';

export interface UserInfo {
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  street: string;
  city: string;
  postalCode: string;
  country: string;
};

interface Token {
  userId: number | undefined;
};

function error() {
  return (
    <div className="web-view-container">
      <div id="order-view-error" className="title-wrapper row">
        <h1>Ett fel inträffade!</h1>
      </div>
    </div>
  );
}

export default function OrderView(props: { location: { search: string, state?: { userInfo: UserInfo } } }) {
  const controller = new AbortController();
  const signal = controller.signal;
  const isMounted = React.useRef(true);
  const appContext = React.useContext(AppContext);
  const token = new URLSearchParams(props.location.search).get('token') || '';

  const [hasError, setHasError] = useState(() => {
    const decodedToken = jwt.decode(token) as Token || {};
    return !token || Object.entries(decodedToken).length === 0 || appContext.upgradePrice.status === 'ERROR';
  });

  const [preFilledValues, setPreFilledValues] = useState(() => {
    if (hasError) return {} as { status: string; userInfo: UserInfo; };
    if (props.location.state && props.location.state.userInfo) {
      const { userInfo } = props.location.state;
      return {
        status: 'complete',
        userInfo
      };
    }
    return {
      status: '',
      userInfo: {}
    };
  });

  const [formEnabled, setFormEnabled] = useState(!(!props.location.state || !props.location.state.userInfo));
  const [userInfo, setUserInfo] = useState({
    data: {},
    isValid: false
  });

  const [creditCardInfo, setCreditCardInfo] = useState({
    data: {} as {
      paymentMethod: {
        type: string,
        encryptedCardNumber: string,
        encryptedExpiryMonth: string,
        encryptedExpiryYear: string,
        encryptedSecurityCode: string
      }
    },
    isValid: false
  });

  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);

  useEffect(() => {
    if (hasError) return;
    if (appContext.upgradePrice.status === 'ERROR') {
      setHasError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appContext.upgradePrice.status]);

  useEffect(() => {
    if (hasError) return;
    if (preFilledValues.status) return;

    setPreFilledValues({
      ...preFilledValues,
      status: 'pending'
    });

    fetch(
      `${BACKEND_API_ROOT}/${BACKEND_API_VERSION}/users/mine`,
      {
        headers: {
          token
        },
        signal
      }
    ).then((response) => {
      if (isMounted.current) {
        if (response.ok) {
          response.json().then((userInfo) => {
            setPreFilledValues({
              status: 'complete',
              userInfo
            });
          });
        } else {
          setHasError(true);
        }
        setFormEnabled(true);
      }
    }).catch((reason) => {
      if (reason instanceof DOMException) {
        if (reason.name !== 'AbortError') {
          console.log(reason);
        }
      } else {
        console.log(reason);
      }
    });
  }, [hasError, token, preFilledValues, signal]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (hasError) {
    return error();
  }

  if (preFilledValues.status === 'pending') {
    return (
      <div className="web-view-container" data-testid="order-view__spinner">
        <div id="order-view-content">
          <Processing title="Förbereder beställning..." />
        </div>
      </div>
    );
  }

  const linkToTerms = 'https://www.telia.se/dam/jcr:a347da10-272d-49c1-be6b-d340e8e46fc3/Slutanvandaravtal_TeliaSense_170823%5B3%5D.pdf';

  return (
    <div className="web-view-container">
      <ScrollToTop />
      <div id="order-view-content">
        {
          <>
            <div id="title-header" className="purple">
              <h2>Din Beställning</h2>
            </div>
            <div className="row">
              <FeatureList title="TELIA SENSE PREMIUM" features={premiumFeatures} />
            </div>
            <div className="row">
              <PriceInfo price={appContext.upgradePrice.priceWithTaxTotal} unitString={appContext.upgradePrice.priceUnit} />
            </div>
            <div className="row">
              <UserInfoForm title="Personuppgifter" data={preFilledValues.userInfo} enabled={formEnabled} onChange={setUserInfo} />
            </div>
            <div className="row">
              <AdyenCreditCardForm title="Betaluppgifter" onChange={setCreditCardInfo} />
            </div>
            <div className="row">
              <label className="checkbox">Jag godkänner <a href={linkToTerms} target="_blank" rel="noopener noreferrer">användaravtalet</a>
                <input name="termsCheckbox" type="checkbox" onChange={(event) => setHasAcceptedTerms(event.target.checked)} />
                <span className="checkmark" />
              </label>
            </div>
            <div className="purchase-wrapper footer">
              {(userInfo.isValid && creditCardInfo.isValid && hasAcceptedTerms && !isNaN(appContext.upgradePrice.priceWithTaxTotal)) ?
                <Link className="button purple" to={{
                  pathname: '/processing',
                  search: `?${(new URLSearchParams({ token })).toString()}`,
                  state: {
                    userInfo: userInfo.data,
                    creditCardInfo: creditCardInfo.data
                  }
                }}>Uppgradera</Link> :
                <button disabled={true} className="button gray no-border">Uppgradera</button>
              }
            </div>
          </>
        }
      </div>
    </div>
  );
}

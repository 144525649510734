import '../styles/web-view.scss';
import '../styles/title-header.scss';
import '../styles/order-view-footer.scss';
import '../styles/order-view-confirmation.scss';
import React, { useEffect, useState, useCallback } from 'react';
import Confirmation from '../components/confirmation';
import { BACKEND_API_ROOT, BACKEND_API_VERSION, REACT_APP_POLLING_RETRY_DELAY_MS } from '../config';
import * as jwt from 'jsonwebtoken';
import Processing from '../components/processing';

export interface OrderData {
  creditCardInfo: {
    paymentMethod: {
      encryptedCardNumber: string,
      encryptedExpiryMonth: string,
      encryptedExpiryYear: string,
      encryptedSecurityCode: string
    }
  },
  userInfo: {
    firstName: string;
    lastName: string;
    email: string;
    street: string;
    postalCode: string;
    city: string;
    country: string;
    telephone: string
  }
}

interface Token {
  saveCardTransactionUuid?: string;
  paymentTransactionUuid?: string;
  orderId?: string;
};

interface Redirect {
  url: string;
  method: 'POST';
  data: {
    MD: string;
    PaReq: string;
  }
};

function error() {
  return (
    <div className="web-view-container">
      <div id="order-view-error" className="title-wrapper row">
        <h1>Ett fel inträffade!</h1>
      </div>
    </div>
  );
}

/**
 * Processing status refers to the page's status, rather than individual
 * operations to get the page to a final stage. The process can have status:
 *  - PENDING; if processing is ongoing
 *  - SUCCEEDED; if processing is finished and was successful
 *  - FAILED; if processing is finished and was not successful
 */
enum ProcessingStatus {
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED'
}

interface ProcessingState {
  status: ProcessingStatus;
  reason?: string;
}

/**
 * Operations refer to steps the processing view has to take to reach a final
 * processing status. An operation's status will be:
 *  - ASLEEP; when nothing has happened yet, eg. initial declaration of the state
 *  - INITIALIZED; when the an attempt for a result is started, eg. a fetch request
 *  - PENDING; if some partial result has been received but process is not yet finished
 *  - REDIRECT; if the user needs to be redirected to complete the operation
 *  - FAILED; if the operation finished but was not successful
 *  - SUCCEEDED; if the operation finished and was successful
 */
enum OperationStatus {
  ASLEEP = 'ASLEEP',
  INITIALIZED = 'INITIALIZED',
  PENDING = 'PENDING',
  UPDATING = 'UPDATING',
  REDIRECT = 'REDIRECT',
  FAILED = 'FAILED',
  SUCCEEDED = 'SUCCEEDED',
}

interface OperationState {
  status: OperationStatus;
  reason?: string;
  retries?: number;
  redirectData?: Redirect;
  type?: string
}

export default function ProcessingView(props: { location: { search: string, state?: OrderData } }) {
  const controller = new AbortController();
  const signal = controller.signal;
  const isMounted = React.useRef(true);

  const [token, setToken] = useState(new URLSearchParams(props.location.search).get('token') || '');
  const decodedToken = jwt.decode(token) as Token || {};
  const [hasError, setHasError] = useState(() => {
    return !token || Object.entries(decodedToken).length === 0;
  });

  const [processingState, setProcessingState] = useState(() => {
    const status = !props.location.state && !decodedToken.saveCardTransactionUuid ? ProcessingStatus.FAILED : ProcessingStatus.PENDING;
    const reason = (status === ProcessingStatus.FAILED) ? 'Could not initialize processing state' : undefined;
    return {
      status,
      reason
    } as ProcessingState;
  });

  const [saveCardState, setSaveCardState] = useState(() => {
    const status = !props.location.state && decodedToken.saveCardTransactionUuid ? OperationStatus.PENDING : OperationStatus.ASLEEP;
    const retries = 0;
    return {
      status,
      retries
    } as OperationState;
  });

  const [saveCardReferenceState, setSaveCardReferenceState] = useState({
    status: (decodedToken.paymentTransactionUuid) ? OperationStatus.SUCCEEDED : OperationStatus.ASLEEP
  } as OperationState);

  const [customerAccountState, setCustomerAccountState] = useState({
    status: OperationStatus.ASLEEP
  } as OperationState);

  const [updateSubscriptionState, setUpdateSubscriptionState] = useState({
    status: OperationStatus.ASLEEP
  } as OperationState);

  function updateToken(newToken: string) {
    setToken(newToken);
    var newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + `?token=${newToken}`;
    window.history.replaceState({ path: newUrl }, '', newUrl);
  }

  // Get customer information
  useEffect(() => {
    if (hasError) return;
    if (processingState.status !== ProcessingStatus.PENDING) return;
    if (customerAccountState.status !== OperationStatus.ASLEEP) return;

    setCustomerAccountState({
      status: OperationStatus.INITIALIZED
    });

    fetch(
      `${BACKEND_API_ROOT}/${BACKEND_API_VERSION}/users/mine/account`,
      {
        headers: {
          token
        },
        signal
      }
    ).then(async (response) => {
      if (isMounted.current) {
        if (response.ok) {
          const { type } = await response.json() as { type: string };
          setCustomerAccountState({
            status: OperationStatus.SUCCEEDED,
            type
          });
        } else {
          controller.abort();
          setHasError(true);
        }
      }
    }).catch((reason) => {
      if (reason instanceof DOMException) {
        if (reason.name !== 'AbortError') {
          controller.abort();
          setHasError(true);
        }
      } else {
        controller.abort();
        setHasError(true);
      }
    });

  }, [hasError, token, signal, controller, processingState.status, customerAccountState.status]);

  // Save address and start save card operation
  useEffect(() => {
    if (hasError) return;
    if (processingState.status !== ProcessingStatus.PENDING) return;
    if (saveCardState.status !== OperationStatus.ASLEEP) return;

    setSaveCardState({ status: OperationStatus.INITIALIZED });

    fetch(
      `${BACKEND_API_ROOT}/${BACKEND_API_VERSION}/users/mine`,
      {
        method: 'POST',
        body: JSON.stringify(props.location.state!.userInfo),
        headers: {
          token
        },
        signal
      }
    ).then(async (response) => {
      if (isMounted.current) {
        if (response.ok) {
          const {
            encryptedCardNumber,
            encryptedExpiryMonth,
            encryptedExpiryYear,
            encryptedSecurityCode
          } = props.location.state!.creditCardInfo.paymentMethod;

          const saveCardData = {
            encryptedCardNumber,
            encryptedExpiryMonth,
            encryptedExpiryYear,
            encryptedSecurityCode
          };

          fetch(
            `${BACKEND_API_ROOT}/${BACKEND_API_VERSION}/cards`,
            {
              method: 'POST',
              body: JSON.stringify(saveCardData),
              headers: {
                token
              },
              signal
            }
          ).then(async (response) => {
            if (isMounted.current) {
              if (response.ok) {
                updateToken((await response.json()).token);
                setSaveCardState({
                  status: OperationStatus.PENDING,
                  retries: 0
                });
              } else {
                controller.abort();
                setHasError(true);
              }
            }
          }).catch((reason) => {
            if (reason instanceof DOMException) {
              if (reason.name !== 'AbortError') {
                controller.abort();
                setHasError(true);
              }
            } else {
              controller.abort();
              setHasError(true);
            }
          });
        } else {
          controller.abort();
          setHasError(true);
        }
      }
    }).catch((reason) => {
      if (reason instanceof DOMException) {
        if (reason.name !== 'AbortError') {
          controller.abort();
          setHasError(true);
        }
      } else {
        controller.abort();
        setHasError(true);
      }
    });
  }, [hasError, token, signal, controller, processingState.status, props.location.state, saveCardState.status]);

  // Check transaction status
  useEffect(() => {
    if (hasError) return;
    if (processingState.status !== ProcessingStatus.PENDING) return;
    if (saveCardState.status !== OperationStatus.PENDING) return;

    if (saveCardState.retries! < 5) {
      setSaveCardState({
        ...saveCardState,
        status: OperationStatus.UPDATING
      });

      fetch(
        `${BACKEND_API_ROOT}/${BACKEND_API_VERSION}/transactions/mine/status`,
        {
          method: 'GET',
          headers: {
            token
          },
          signal
        }
      ).then(async (response) => {
        if (isMounted.current) {
          if (response.ok) {
            const { status, type } = await response.json() as { status: string, type: string };

            switch (status) {
              case 'redirect':
                fetch(
                  `${BACKEND_API_ROOT}/${BACKEND_API_VERSION}/transactions/mine/redirect`,
                  {
                    headers: {
                      token
                    },
                    signal
                  }
                ).then(async (response) => {
                  if (isMounted.current) {
                    if (response.ok) {
                      setSaveCardState({
                        status: OperationStatus.REDIRECT,
                        redirectData: await response.json() as Redirect
                      });
                    } else {
                      controller.abort();
                      setHasError(true);
                    }
                  }
                }).catch((reason) => {
                  if (reason instanceof DOMException) {
                    if (reason.name !== 'AbortError') {
                      controller.abort();
                      setHasError(true);
                    }
                  } else {
                    controller.abort();
                    setHasError(true);
                  }
                });
                break;
              case 'pending':
                setTimeout(() => {
                  if (isMounted.current) {
                    setSaveCardState((prev) => ({
                      status: OperationStatus.PENDING,
                      retries: prev.retries! + 1
                    }));
                  }
                }, Number(REACT_APP_POLLING_RETRY_DELAY_MS));
                break;
              case 'succeeded':
                setSaveCardState({ status: OperationStatus.SUCCEEDED });
                break;
              default:
                setProcessingState({
                  status: ProcessingStatus.FAILED,
                  reason: `${type} operation failed`
                });
            }
          } else {
            controller.abort();
            setHasError(true);
          }
        }
      }).catch((reason) => {
        if (reason instanceof DOMException) {
          if (reason.name !== 'AbortError') {
            controller.abort();
            setHasError(true);
          }
        } else {
          controller.abort();
          setHasError(true);
        }
      });
    } else {
      controller.abort();
      setHasError(true);
    }
  }, [hasError, token, signal, controller, processingState.status, saveCardState]);

  // Save card reference
  useEffect(() => {
    if (hasError) return;
    if (processingState.status !== ProcessingStatus.PENDING) return;
    if (saveCardState.status !== OperationStatus.SUCCEEDED) return;
    if (saveCardReferenceState.status !== OperationStatus.ASLEEP) return;

    setSaveCardReferenceState({
      status: OperationStatus.INITIALIZED
    });

    fetch(
      `${BACKEND_API_ROOT}/${BACKEND_API_VERSION}/users/mine/card-reference`,
      {
        method: 'POST',
        headers: {
          token
        },
        signal
      }
    ).then(async (response) => {
      if (isMounted.current) {
        if (response.ok) {
          setSaveCardReferenceState({
            status: OperationStatus.SUCCEEDED
          });
        } else {
          controller.abort();
          setHasError(true);
        }
      }
    }).catch((reason) => {
      if (reason instanceof DOMException) {
        if (reason.name !== 'AbortError') {
          controller.abort();
          setHasError(true);
        }
      } else {
        controller.abort();
        setHasError(true);
      }
    });

  }, [hasError, token, signal, controller, processingState.status, saveCardState.status, saveCardReferenceState.status]);

  // Update subscription state
  useEffect(() => {
    if (hasError) return;
    if (customerAccountState.status !== OperationStatus.SUCCEEDED) return;
    if (saveCardReferenceState.status !== OperationStatus.SUCCEEDED) return;
    if (updateSubscriptionState.status !== OperationStatus.ASLEEP) return;
    if (customerAccountState.type !== 'basic') return;

    setUpdateSubscriptionState({
      status: OperationStatus.INITIALIZED
    });

    fetch(
      `${BACKEND_API_ROOT}/${BACKEND_API_VERSION}/subscriptions/mine`,
      {
        method: 'POST',
        body: JSON.stringify({ status: 'active' }),
        headers: {
          token
        },
        signal
      }).then(response => {
        if (!isMounted.current) {
          return;
        }

        if (response.ok) {
          setUpdateSubscriptionState({
            status: OperationStatus.SUCCEEDED
          });
        } else {
          controller.abort();
          setHasError(true);
        }
      }).catch(reason => {
        if (reason instanceof DOMException) {
          if (reason.name !== 'AbortError') {
            controller.abort();
            setHasError(true);
          }
        } else {
          controller.abort();
          setHasError(true);
        }
      });
  }, [controller, customerAccountState.status, customerAccountState.type, hasError, saveCardReferenceState.status, signal, token, updateSubscriptionState.status]);

  // Finish processing
  useEffect(() => {
    if (processingState.status !== ProcessingStatus.PENDING) return;
    if (customerAccountState.status !== OperationStatus.SUCCEEDED) return;

    switch (customerAccountState.type) {
      case 'trial-premium':
      case 'premium':
        if (saveCardState.status === OperationStatus.SUCCEEDED) {
          setProcessingState({ status: ProcessingStatus.SUCCEEDED });
        }
        break;
      case 'basic':
        if (updateSubscriptionState.status === OperationStatus.SUCCEEDED) {
          setProcessingState({ status: ProcessingStatus.SUCCEEDED });
        }
        break;
    }
  }, [customerAccountState.status, customerAccountState.type, saveCardState.status, processingState.status, updateSubscriptionState.status]);
  // }, [customerAccountState.status, customerAccountState.type, saveCardState.status, processingState.status, paymentReferenceState.status]);

  const formRef = useCallback((form) => {
    if (!form) return;
    (form as HTMLFormElement).submit();
  }, []);

  // Cleanup mount state and ongoing fetch operations
  // when leaving processing view
  useEffect(() => {
    return () => {
      isMounted.current = false;
      controller.abort();
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  if (hasError) {
    return error();
  }

  return (
    <div data-testid="processing-view" className="web-view-container">
      <div id="processing-view-content">
        {
          (![ProcessingStatus.SUCCEEDED, ProcessingStatus.FAILED].includes(processingState.status)) &&
          <Processing title="Din beställning bearbetas..." />
        }
        {
          [ProcessingStatus.SUCCEEDED, ProcessingStatus.FAILED].includes(processingState.status) &&
          <Confirmation
            successful={processingState.status === ProcessingStatus.SUCCEEDED}
            token={token}
            userInfo={props.location.state ? props.location.state!.userInfo : undefined}
            accountType={customerAccountState.type!}
          />
        }
        {
          saveCardState.status === OperationStatus.REDIRECT &&
          <form ref={formRef} data-testid="redirect-form" action={saveCardState.redirectData!.url} method={saveCardState.redirectData!.method}>
            <input readOnly name='MD' value={saveCardState.redirectData!.data!.MD || ''} type="hidden" />
            <input readOnly name='PaReq' value={saveCardState.redirectData!.data!.PaReq || ''} type="hidden" />
            <input readOnly name='TermUrl' value={`${BACKEND_API_ROOT}/${BACKEND_API_VERSION}/callbacks/adyen?token=${token}`} type="hidden" />
          </form>
        }
      </div>
    </div>
  );
}

import '../styles/web-view.scss';
import '../styles/upgrade-view.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import SellingPoints from '../components/selling-points';
import FeatureList from '../components/feature-list';
import * as jwt from 'jsonwebtoken';
import Parser from 'html-react-parser';
import {
  basicFeatures,
  premiumFeatures,
  upgradeViewContent,
  faqList
} from '../data/content';
import FAQ from '../components/faq';
import CallSupport from '../components/call-support';
import { AppContext } from '../context/app-context';
import LoadingSpinner from '../assets/icons/loading-bars.gif';

interface IProps {
  location: {
    search: string;
  };
}

function error() {
  return (
    <div className="web-view-container">
      <div id="upgrade-view-error" className="title-wrapper row">
        <h1>Ett fel inträffade!</h1>
      </div>
    </div>
  );
}

function UpgradeView(props: IProps) {
  const token = new URLSearchParams(props.location.search).get('token');
  const appContext = React.useContext(AppContext);

  if (!token) {
    console.log('Missing token');
    return error();
  }

  const decoded = jwt.decode(token) as {
    upgradeContext: 'wifi' | 'journal' | 'general';
    provisioningId: string;
    userId: number;
  };

  if (!decoded) {
    console.log('Failed to parse token');
    return error();
  }

  const dynamicContent = upgradeViewContent[decoded.upgradeContext] || upgradeViewContent['general'];
  const inlineSpinner = `<img class="inline-loading-spinner" src="${LoadingSpinner}" alt="laddar pris" />`;

  const upgradePrice = (() => {
    if (appContext.upgradePrice.status === 'COMPLETE') {
      return `${appContext.upgradePrice.priceWithTaxTotal} ${appContext.upgradePrice.priceUnit}`;
    }

    if (appContext.upgradePrice.status === 'ERROR') {
      return 'Kunde inte hämta pris';
    }

    return false;
  })();

  return (
    <div className="web-view-container">
      <div id="upgrade-view-content">
        <div className="title-wrapper">
          <h1 className="page-title">{Parser(dynamicContent.heading)}</h1>
        </div>
        <div className="row">
          <SellingPoints uspList={dynamicContent.sellingPoints} />
        </div>
        <section className="row features-wrapper">
          <div className="title-wrapper">
            <h1>Premium utan bindningstid</h1>
            <p>{Parser(dynamicContent.description)}</p>
          </div>
          <FeatureList features={premiumFeatures} title="Premium" />
          <FeatureList features={basicFeatures} title="Bas" />
        </section>
        <section className="row faq-wrapper">
          <FAQ questions={faqList(upgradePrice || '**')} title="Våra vanligaste frågor" />
        </section>
        <div className="call-support-wrapper">
          <CallSupport title="Har du frågor?" button="Ring kundtjänst" />
        </div>
        <div className="purchase-wrapper">
          {appContext.upgradePrice.status === 'ERROR' && <button disabled className="button gray">{upgradePrice}</button> }
          {appContext.upgradePrice.status === 'PENDING' && (
            <button disabled className="button gray"><span className="flex-center">{Parser(dynamicContent.buttonText(upgradePrice ? upgradePrice : inlineSpinner))}</span></button>
          )}
          {appContext.upgradePrice.status === 'COMPLETE' && (
            <Link
              className="button purple"
              data-testid="upgrade-view__order-button"
              to={{ pathname: '/order', search: `?token=${token}` }}
            >
              <span className="flex-center">{Parser(dynamicContent.buttonText(upgradePrice ? upgradePrice : inlineSpinner))}</span>
            </Link>
          )}
          <div className="disclaimer">
            <p className="text small italic">Ingen bindningstid - Avsluta Premium när du vill</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpgradeView;

import React from 'react';
import LoadingSpinner from '../assets/icons/loading-bars.gif';
import '../styles/processing.scss';

interface IProps {
  title?: string;
}

const Processing = (props: IProps) => {
  return (
    <div data-testid="processing" className="processing">
      {props.title && <h1>{props.title}</h1>}
      <img src={LoadingSpinner} alt="processing" />
    </div>
  );
};

export default Processing;

import '../styles/call-support.scss';
import React from 'react';

interface IProps {
  title: string;
  button: string;
}

const CallSupport = (props: IProps) => {
  return (
    <>
      <h3 className="call-support-title">{props.title}</h3>
      <a className="button purple white-border small" id="telephone-link" href="tel:0771226644">{props.button}</a>
    </>
  );
};

export default CallSupport;
